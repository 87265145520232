import { Icon } from '@chakra-ui/react';

export function TwinkleIcon(props) {
  return (
    <Icon viewBox="0 0 105 104" fill="none" {...props}>
      <path
        d="M52.0078 102C52.0078 68.229 35.7788 52 2.00781 52C35.7788 52 52.0078 35.771 52.0078 2C52.0078 35.771 68.2368 52 102.008 52C68.2368 52 52.0078 68.229 52.0078 102Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
