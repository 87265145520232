import { Icon } from '@chakra-ui/react';

export function ArrowRightIcon(props) {
  return (
    <Icon width="50" height="50" viewBox="0 0 50 50" fill="none" {...props}>
      <path
        d="M29.0938 44C29.0937 36.4491 47.8904 25.2034 47.8904 25.2034C47.8904 25.2034 29.0937 14.4675 29.0937 6.40674"
        stroke="#EA593D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 25.2031L47.8377 25.2031"
        stroke="#EA593D"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </Icon>
  );
}
