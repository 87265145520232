import { useState, useEffect } from 'react';
import { Flex, Image, Link, useColorMode } from '@chakra-ui/react';

import { motion } from 'framer-motion';

import UBCLogo from 'assets/images/UBCLogo.svg';
import UBCLogoDark from 'assets/images/UBCLogoDark.svg';

const MotionFlex = motion(Flex);

const HeaderLight = () => {
  const [isShow, setIsShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const { colorMode } = useColorMode();

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== 'undefined') {
        if (window.scrollY > lastScrollY + 0.5 && window.scrollY > 80) {
          setIsShow(false);
        } else {
          setIsShow(true);
        }

        setLastScrollY(window.scrollY);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  const isLight = colorMode === 'light';
  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: -80 },
  };
  return (
    <MotionFlex
      overflow="hidden"
      transition={{ duration: 0.5 }}
      style={{ originY: 0 }}
      animate={isShow ? 'open' : 'closed'}
      variants={variants}
      bgColor="bg-secondary"
      position="fixed"
      zIndex="20"
      w="100%"
      h="80px"
      justify="center"
    >
      <Flex w="120px" h="53px" mt="15px" position="relative" as={Link} href="/">
        <Image
          top="0"
          position="absolute"
          w="100%"
          src={UBCLogo}
          zIndex={isLight && 3}
        />
        <Image position="absolute" w="100%" src={UBCLogoDark} zIndex="2" />
      </Flex>
    </MotionFlex>
  );
};

export default HeaderLight;
