import React from 'react';

import { Flex, Text, VStack } from '@chakra-ui/react';
import Header from 'components/header/Header';
import Footer from 'sections/Footer';

const TERMS_LIST = [
  {
    content:
      '欲參加「第二屆 UBC 聯合讀書會結業式」（以下稱「本活動」）之參加人於報名購票之同時，即視為同意接受本注意事項之規範；如不願同意本注意事項之全部或一部份，請勿參加本活動。',
  },
  {
    title: '參加人條款',
    content:
      '參加人提交報名資料，即視為參加人同意 UBC 聯合讀書會依告知事項蒐集、處理及利用參加人的個人資料，參加人於報名本活動之同時應確保提供資料之正確性，如因資料有誤、無法聯繫，或逾期報到、身分不符者，均視為放棄參加資格， UBC 聯合讀書會不提供任何補償，且 UBC 聯合讀書會得視狀況決定是否另行遞補之。',
  },
  {
    title: '參加人義務聲明',
    content:
      '參加人應具有完全行為能力而得為有效法律行為。如您為限制行為能力人或無行為能力人者，當您完成報名購票則表示您的法定代理人、監護人或輔助人已經閱讀且知悉並同意本條款。本服務之參加人應維持並更新個人資料，確保其為正確、最新及完整。使用者不應使用不實、不完整的資料，或與其個人真實資料無關之資訊，包括但不限於使用模仿他人姓名或使用攻擊性的字眼、任何種族歧視的文字及任何會傷害、侵害他人權利之文字作為參加人名稱。參加人有義務妥善保管付款結果通知信，且不應將其轉讓或出借給他人使用，如發現因會員的保管疏忽導致遭他人非法使用，我們不負擔任何責任。',
  },
  {
    title: '付款方式',
    content:
      '參加人進行報名購票之時，即代表同意提供有效的付款工具支付費用，包含使用信用卡、簽帳卡以及ATM 轉帳付款方式。當參加人提供付款工具即是確認已獲付款許可，得以使用該付款工具。當參加人進行交易付款時，即授權 UBC 聯合讀書會（以及指定付款處理人- 藍新金流）向參加人指定為交易用途之付款工具收取全額款項，同時授權藍新金流收集並儲存該付款工具以及其他相關交易資訊。如果參加人使用信用卡或簽帳卡付款，藍新金流即會從發卡單位取得特定金額的預先核准，該筆金額最高可能為購買項目的價格全額，倘若參加人在交易完成前取消交易，可能導致預先核准的款項金額無法立即取得使用。如果參加人使用簽帳卡付款，且所交易金額導致帳戶透支，或有其他銀行所收取之手續費，須由參加人自行負擔該筆費用。',
  },
  {
    title: '退款方式',
    content:
      '活動開始前一週（2023.06.25 23:59）為最後退款日，如因個人因素申請退款，將扣除 30% 退款手續費，剩餘款項退款至您的帳戶。活動開始一週內（2023.06.26 0:00～），因活動場地與餐點數量已確認，恕不接受退款，但可將門票轉讓給您朋友。若經 UBC 聯合讀書會認定參加人無法實際出席活動（以 UBC 聯合讀書會紀錄為準），本活動費用將不予退還，參加人不得以任何形式向 UBC 聯合讀書會要求賠償或補償。如需退款請寄信至 uxbookclub.contact@gmail.com ，主旨「 第二屆 UBC 聯合讀書會結業式｜退費相關事宜」，並附上訂單截圖、說明退款原因、退款帳號，將由團隊夥伴審核後受理退費。',
  },
  {
    title: '隱私權政策',
    content:
      'UBC 聯合讀書會或 UBC 聯合讀書會所委託之第三人得於本活動時對參加人進行攝影、訪問及拍照， UBC 聯合讀書會並享有著作人格權及著作財產權；參加人之肖像權及其他可能涉及之智慧財產權等均不可撤回地授權 UBC 聯合讀書會為永久、無償及不限區域使用，且同意 UBC 聯合讀書會於各媒體（包括但不限於平面、電視及社群）為任何使用，包括但不限於商業使用。UBC 聯合讀書會為設計、製作、剪輯、重製、公開發表本活動攝影、訪問及照片等之目的，得自己或委由第三人就該等攝影、訪問及拍照作品之全部或一部，為重製、編輯、修改、公開發表、剪輯、增減、及標示或與他人之作品之全部或一部為組合或結合，不論使用範圍之多寡及作品之完整與否。 UBC 聯合讀書會亦無須就前開使用之內容、態樣及形式，提供參加人進行審閱或同意。',
  },
  {
    title: '活動變更',
    content:
      '若逢颱風或其他不可抗力之因素、天災，由主辦單位以參與者安全考量，決定是否取消，如活動因有任何變更或補充事項，將隨時公佈於 UBC 聯合讀書會之網站以及粉絲專頁公告為準。UBC 聯合讀書會保留隨時增刪、調整、暫停或取消本活動內容及細節之權利，無須另行通知；UBC 聯合讀書會對本活動擁有最終解釋權。',
  },
];

const TermsItem = props => {
  const { title, content } = props;
  return (
    <VStack spacing="8px" align="flex-start" w="100%">
      <Text textStyle="content02" color="brand-color" fontWeight="600">
        {title}
      </Text>
      <Text textStyle="content02" opacity="0.9">
        {content}
      </Text>
    </VStack>
  );
};

export function TermsPage() {
  return (
    <>
      <Header />
      <Flex w="100%" py="15vh" justify="center">
        <Flex
          direction="column"
          w={{ base: '100%', lg: '80%' }}
          px="24px"
          maxW="1200px"
          algin="flex-start"
        >
          <VStack spacing="40px" align="flex-start" w="100%">
            {TERMS_LIST.map((item, index) => (
              <TermsItem key={item.title} {...item} />
            ))}
          </VStack>
          {/* <VStack spacing="10px" align="flex-start" w="100%">
            <Text textStyle="content02" color="brand-color" fontWeight="600">
              使用者條款
            </Text>
            <Text textStyle="content02">
              本使用者條款（以下稱「本條款」）適用於所有位於及連結於
              https://ubc.pickup-design.com/
              網域之網站（以下稱「我們」或「我們的」），以及包含集團內之其他關係企業，包括但不限於
              UBC 等。
            </Text>
            <VStack>
              <Text textStyle="content02" color="brand-color">
                1. 服務條款
              </Text>
              <Text textStyle="content02">
                本條款適用於所有連結至我們提供之服務（以下稱「本服務」）之會員或訪客（以下合稱「使用者」、「您」），透過持續使用本服務，即表示您已知悉且同意本條款。我們在法律允許的範圍內，有權隨時修改、調整本條款中的任何條款內容，並將以公告形式發布通知，若您不同意本條款之部分或全部內容，您應立即停止使用本服務。
              </Text>
            </VStack>

            <VStack>
              <Text textStyle="content02" color="brand-color">
                保密義務
              </Text>
              <Text textStyle="content02">
                您因使用本服務而取得或可得取得之資訊，包括但不限於個人資料、分析數據、報價等內容，您同意以不低於業界之水平負有保密義務。
              </Text>
            </VStack>
            <VStack>
              <Text textStyle="content02" color="brand-color">
                客服
              </Text>
              <Text textStyle="content02">聯絡信箱</Text>
            </VStack>
          </VStack> */}
        </Flex>
      </Flex>
      <Footer />
    </>
  );
}
