import { Icon } from '@chakra-ui/react';

export function LightIcon(props) {
  return (
    <Icon viewBox="0 0 78 57" width="78" height="57" fill="none" {...props}>
      <path
        d="M33.3203 19.3911L41.4178 33.4163"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M71.4609 7.88867L67.2694 23.5318"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M4.55469 46.5112L20.1978 50.7028"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </Icon>
  );
}
