import { HStack, Box, Text } from '@chakra-ui/react';

const FormTitle = props => {
  return (
    <HStack>
      <Box w="8px" h="100%" bgColor="orange.500"></Box>
      <Text lineHeight="100%" textStyle="content01" fontWeight="700">
        {props.children}
      </Text>
    </HStack>
  );
};

export default FormTitle;
